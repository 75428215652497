import { useLazyQuery } from '@apollo/client';
import qs from 'query-string';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Query, QueryTalentInvitationsInfoArgs } from 'generated/graphql-types';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { pathManager } from 'routes/consts';

import { GET_INVITATION_INFO } from './queries';

export const useRequestInviteData = () => {
  const { data: userData, isTalent } = useCurrentUser();

  const id = userData?.currentTalent?.id as string;
  const [getInvitationInfo, { data, loading }] = useLazyQuery<
    Query,
    QueryTalentInvitationsInfoArgs
  >(GET_INVITATION_INFO, {
    fetchPolicy: 'network-only',
    variables: { inviting_talent_id: id },
  });

  useEffect(() => {
    if (id && !data && isTalent) {
      getInvitationInfo();
    }
  }, [id, data, getInvitationInfo, isTalent]);

  return {
    data,
    isInvitationAccepted: userData?.currentTalent?.is_invitation_accepted,
    isLoading: loading,
    id,
    getInvitationRequest: getInvitationInfo,
  };
};

export const useModalState = (modalId: string) => {
  const history = useHistory();
  const { modal } = qs.parse(history.location.search);
  const handleClose = useCallback(() => {
    history.push(pathManager.talent.invite.generatePath());
  }, [history]);
  const handleOpen = useCallback(() => {
    history.push({
      pathname: pathManager.talent.invite.generatePath(),
      search: qs.stringify({ modal: modalId }),
    });
  }, [history, modalId]);
  return {
    isOpen: !!(modal && modal === modalId),
    handleClose,
    handleOpen,
  };
};
