import { ConfirmProvider } from 'material-ui-confirm';
// @ts-ignore
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom';
// @ts-ignore
import { MixpanelProvider } from 'react-mixpanel';
import 'react-quill/dist/quill.snow.css';

import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';

import Auth0Provider from 'auth0/Auth0Provider';
import SnackbarProvider from 'components/snackbar/SnackbarProvider';
import ApolloProvider from 'graphql/client/ApolloProvider';
import Router from 'routes';
import defaultTheme from 'themes/default';
import { setYupLocale } from 'utils/yup';

setYupLocale();
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

ReactDOM.render(
  <MixpanelProvider mixpanel={mixpanel}>
    <ThemeProvider theme={defaultTheme}>
      <Auth0Provider>
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <SnackbarProvider>
            <ApolloProvider>
              <ConfirmProvider
                defaultOptions={{
                  confirmationButtonProps: {
                    variant: 'contained',
                    color: 'primary',
                  },
                }}
              >
                <CssBaseline />
                <Router />
              </ConfirmProvider>
            </ApolloProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </Auth0Provider>
    </ThemeProvider>
  </MixpanelProvider>,
  document.getElementById('root'),
);
