import { gql } from '@apollo/client';

export default gql`
  query GetTalent($id: ID!) {
    talent(id: $id) {
      first_name
      last_name
      address
      location
      recent_position_title
      about
      is_invitation_accepted
      rate
      rate_min
      rate_max
      email
      available_date
      hours_per_week
      available_now
      hours_per_week
      companies {
        id
        name
      }
      avatar {
        avatar
      }
      source {
        id
        name
        logo
        created_at
        updated_at
      }
      talent_work_history: talent_work_history {
        position_title
        company_name
        worked_from
        worked_to
      }
      skills(first: 15) {
        data {
          id
          skill_type
          name
        }
      }
      invited_by {
        company_name
        first_name
        last_name
        invitation_type
        id
      }
      documents {
        title
        document
        size
        content_type
        hash
        created_at
        updated_at
      }
      talent_data {
        facebook_profile_link
        linkedin_profile_link
        upwork_profile_link
        phone
        vat_number
      }
    }
  }
`;
