import { useMediaQuery, useTheme } from '@material-ui/core';

export default () => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));
  const isMD = useMediaQuery(theme.breakpoints.down('md'));
  const isLG = useMediaQuery(theme.breakpoints.down('lg'));
  const isXL = useMediaQuery(theme.breakpoints.down('xl'));
  const isXXL = useMediaQuery(theme.breakpoints.up('xl'));

  return { isXS, isSM, isMD, isLG, isXL, isXXL };
};
