import React from 'react';
import { Link } from 'react-router-dom';

import { Badge, Button, Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { ReactComponent as InvitesIcon } from 'assets/icons/invites.svg';
import * as AuthUtils from 'auth0/utils';
import { useAuth0 } from 'hooks/auth/useAuth0';
import { pathManager } from 'routes/consts';
import { useRequestInviteData } from 'screens/talent/invite-by-talent/hooks';

import useStyles from './styles';

interface TalentInvitesProps {
  isLoading: boolean;
  inventionCount?: number;
}

const TalentInvites = (props: TalentInvitesProps) => {
  const { isLoading } = props;
  const classes = useStyles(props);
  const { user } = useAuth0();
  const isTalent = AuthUtils.isTalent(user);
  const { data, isInvitationAccepted } = useRequestInviteData();
  const invites = data?.talentInvitationsInfo?.invitations_left || 0;

  if (!isTalent || !isInvitationAccepted) {
    return null;
  }

  if (isLoading) {
    return (
      <Grid spacing={2} alignItems="center" container>
        <Grid item>
          <Skeleton
            className={classes.skeleton}
            variant="circle"
            width={30}
            height={30}
          />
        </Grid>
        <Grid item>
          <Skeleton
            className={classes.skeleton}
            width="40px"
            height="20px"
            variant="text"
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Link to={pathManager.talent.invite.generatePath()}>
      <Button
        startIcon={
          <Badge
            classes={{ badge: classes.badge }}
            showZero
            color="primary"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={invites}
            invisible={invites < 1}
          >
            <InvitesIcon />
          </Badge>
        }
        className={classes.root}
      >
        <Box className={classes.text}>Invites</Box>
      </Button>
    </Link>
  );
};

export default TalentInvites;
