import React, { useEffect } from 'react';

import PageLayout from 'components/layout/page-layout';
import { useAuth0 } from 'hooks/auth/useAuth0';

const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({ federated: true, localOnly: false });
  }, [logout]);

  return (
    <PageLayout
      isLoading
      headerProps={{ isLoading: true }}
      drawerProps={{ isLoading: true }}
    />
  );
};

export default Logout;
