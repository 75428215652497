import { gql } from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** A datetime string in ISO 8601 format in UTC with nanoseconds `YYYY-MM-DDTHH:mm:ss.SSSSSSZ`, e.g. `2020-04-20T16:20:04.000000Z`. */
  DateTimeUtc: any;
  /** Email in the format `user@example.com` */
  Email: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: any;
};

export type AvailableJobForTalent = {
  __typename?: 'AvailableJobForTalent';
  job?: Maybe<Job>;
  is_invited: Scalars['Boolean'];
  is_applied: Scalars['Boolean'];
};

export type Avatar = {
  __typename?: 'Avatar';
  avatar: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  id?: Maybe<Scalars['ID']>;
  parent_id?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  company_id?: Maybe<Scalars['Int']>;
  talent_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of ChatMessage items. */
export type ChatMessagePaginator = {
  __typename?: 'ChatMessagePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of ChatMessage items. */
  data: Array<ChatMessage>;
};

export type CommonAppInfo = {
  __typename?: 'CommonAppInfo';
  total_ot_freelancers_count: Scalars['Int'];
  total_ot_freelancers_countries_count: Scalars['Int'];
  total_unique_skills_count: Scalars['Int'];
  total_unique_companies_count: Scalars['Int'];
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  type_of_activity?: Maybe<Scalars['String']>;
  logo?: Maybe<CompanyLogo>;
};

export type CompanyLogo = {
  __typename?: 'CompanyLogo';
  path: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
};

/** A paginated list of Company items. */
export type CompanyPaginator = {
  __typename?: 'CompanyPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Company items. */
  data: Array<Company>;
};

/** Company user roles */
export const CompanyUserRoleEnum = {
  AccountAdmin: 'ACCOUNT_ADMIN',
  AccountUser: 'ACCOUNT_USER'
} as const;

export type CompanyUserRoleEnum = typeof CompanyUserRoleEnum[keyof typeof CompanyUserRoleEnum];
/** Order by clause for the `order_by` argument on the query `currentTalentJobBoardSearch`. */
export type CurrentTalentJobBoardSearchOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: JobOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type CurrentTalentJobBoardSearchResult = TalentJobConnection | Job;

export type DashboardAnalyticsItem = {
  __typename?: 'DashboardAnalyticsItem';
  key: Scalars['String'];
  value: Scalars['Float'];
  slug?: Maybe<Scalars['String']>;
};





export type File = {
  __typename?: 'File';
  path: Scalars['String'];
  hash: Scalars['String'];
  file_type?: Maybe<FileTypeEnum>;
};

export const FileTypeEnum = {
  TalentAvatar: 'TALENT_AVATAR',
  TalentDocument: 'TALENT_DOCUMENT',
  CompanyLogo: 'COMPANY_LOGO',
  CompanyUserAvatar: 'COMPANY_USER_AVATAR'
} as const;

export type FileTypeEnum = typeof FileTypeEnum[keyof typeof FileTypeEnum];
export type InvitationLink = {
  __typename?: 'InvitationLink';
  id: Scalars['ID'];
  url: Scalars['String'];
  inviting_talent?: Maybe<Talent>;
  name?: Maybe<Scalars['String']>;
  is_used: Scalars['Boolean'];
};

export type InvitationLinkInfo = {
  __typename?: 'InvitationLinkInfo';
  id: Scalars['ID'];
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  inviting_person_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  is_used: Scalars['Boolean'];
};

export type Job = {
  __typename?: 'Job';
  id: Scalars['ID'];
  name: Scalars['String'];
  skills?: Maybe<Array<Maybe<JobSkill>>>;
  category?: Maybe<TalentCategory>;
  description?: Maybe<Scalars['String']>;
  pitch?: Maybe<Scalars['String']>;
  link_to_details?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['DateTimeUtc']>;
  end_date?: Maybe<Scalars['DateTimeUtc']>;
  rate_min?: Maybe<Scalars['Float']>;
  rate_max?: Maybe<Scalars['Float']>;
  is_rate_negotiable?: Maybe<Scalars['Boolean']>;
  salary_min?: Maybe<Scalars['Float']>;
  salary_max?: Maybe<Scalars['Float']>;
  is_salary_negotiable?: Maybe<Scalars['Boolean']>;
  max_project_budget?: Maybe<Scalars['Float']>;
  finders_fee?: Maybe<Scalars['Float']>;
  campaign_owner?: Maybe<User>;
  campaign_start_date?: Maybe<Scalars['DateTimeUtc']>;
  campaign_end_date?: Maybe<Scalars['DateTimeUtc']>;
  campaign_duration?: Maybe<Scalars['Int']>;
  campaign_talent_pool?: Maybe<Array<Maybe<SourceTypeEnum>>>;
  is_archived: Scalars['Boolean'];
  matches?: Maybe<Array<Maybe<JobMatch>>>;
  saved_matches?: Maybe<Array<Maybe<JobMatch>>>;
  invitations?: Maybe<Array<Maybe<JobInvitation>>>;
  matches_count: Scalars['Int'];
  matches_with_application_count: Scalars['Int'];
  instant_matches_count: Scalars['Int'];
  location?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  hours_per_week?: Maybe<Scalars['Int']>;
  location_type?: Maybe<JobLocationTypeEnum>;
  is_remote_an_option: Scalars['Boolean'];
  period?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  posted_at?: Maybe<Scalars['DateTimeUtc']>;
  type?: Maybe<JobTypeEnum>;
  is_old_format: Scalars['Boolean'];
  /** Determines whether the job campaign saved as draft */
  is_draft?: Maybe<Scalars['Boolean']>;
  /** Determines whether the job campaign starts from the moment of publication. */
  is_instant_campaign_start: Scalars['Boolean'];
  /** Context related attributes. Presented only if there is talent context */
  is_saved?: Maybe<Scalars['Boolean']>;
  is_applied?: Maybe<Scalars['Boolean']>;
};

export type JobApplication = {
  __typename?: 'JobApplication';
  id: Scalars['ID'];
  job_id: Scalars['ID'];
  talent_id: Scalars['ID'];
  rate?: Maybe<Scalars['Float']>;
  pitch?: Maybe<Scalars['String']>;
};

export type JobBoardInfo = {
  __typename?: 'JobBoardInfo';
  talent_responses_count: Scalars['Int'];
  talent_matches_count: Scalars['Int'];
  talent_responses_count_archived: Scalars['Int'];
  talent_matches_count_archived: Scalars['Int'];
};

export type JobInvitation = {
  __typename?: 'JobInvitation';
  id: Scalars['ID'];
  job_id: Scalars['ID'];
  job: Job;
  talent_id: Scalars['ID'];
  is_declined?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  is_accepted: Scalars['Boolean'];
  application?: Maybe<JobApplication>;
};

export const JobLocationTypeEnum = {
  Remote: 'REMOTE',
  OnSite: 'ON_SITE'
} as const;

export type JobLocationTypeEnum = typeof JobLocationTypeEnum[keyof typeof JobLocationTypeEnum];
export type JobMatch = {
  __typename?: 'JobMatch';
  id: Scalars['ID'];
  talent?: Maybe<Talent>;
  job: Job;
  matched_skills?: Maybe<Array<Maybe<Skill>>>;
  match_percent: Scalars['Int'];
  match_type?: Maybe<JobMatchTypeEnum>;
  is_instant_match?: Maybe<Scalars['Boolean']>;
  is_applied?: Maybe<Scalars['Boolean']>;
  is_invited?: Maybe<Scalars['Boolean']>;
  is_shortlist?: Maybe<Scalars['Boolean']>;
  job_application?: Maybe<JobApplication>;
  job_invitation?: Maybe<JobInvitation>;
};

export const JobMatchTypeEnum = {
  TalentApplication: 'TALENT_APPLICATION',
  InstantMatch: 'INSTANT_MATCH',
  OpentalentSuggestion: 'OPENTALENT_SUGGESTION',
  Invited: 'INVITED',
  Shortlist: 'SHORTLIST'
} as const;

export type JobMatchTypeEnum = typeof JobMatchTypeEnum[keyof typeof JobMatchTypeEnum];
export type JobOrderByClause = {
  /** The column that is used for ordering. */
  column: JobOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for the `orderBy` argument on the query `posts`. */
export const JobOrderByColumn = {
  CampaignStart: 'CAMPAIGN_START',
  RateMin: 'RATE_MIN',
  RateMax: 'RATE_MAX'
} as const;

export type JobOrderByColumn = typeof JobOrderByColumn[keyof typeof JobOrderByColumn];
/** A paginated list of Job items. */
export type JobPaginator = {
  __typename?: 'JobPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Job items. */
  data: Array<Job>;
};

export type JobResponse = {
  __typename?: 'JobResponse';
  talent: Talent;
  job: Job;
  created_at: Scalars['DateTimeUtc'];
};

export type JobResponseCompany = {
  __typename?: 'JobResponseCompany';
  talent: Talent;
  job: Job;
  skills?: Maybe<Array<Maybe<Skill>>>;
  created_at: Scalars['DateTimeUtc'];
};

/** Job requested skill */
export type JobSkill = {
  __typename?: 'JobSkill';
  id: Scalars['ID'];
  skill_type: SkillTypeEnum;
  name: Scalars['String'];
  slug: Scalars['String'];
  job_skill_pivot?: Maybe<JobSkillPivot>;
  created_at?: Maybe<Scalars['DateTimeUtc']>;
  updated_at?: Maybe<Scalars['DateTimeUtc']>;
};

export type JobSkillPivot = {
  __typename?: 'JobSkillPivot';
  is_required?: Maybe<Scalars['Boolean']>;
};

export const JobTypeEnum = {
  Freelance: 'FREELANCE',
  Permanent: 'PERMANENT',
  Project: 'PROJECT'
} as const;

export type JobTypeEnum = typeof JobTypeEnum[keyof typeof JobTypeEnum];
export type Mutation = {
  __typename?: 'Mutation';
  /** Add new skill to catalog */
  createSkill: Skill;
  /** Create talent profile */
  createTalentProfile?: Maybe<Scalars['ID']>;
  /** Update talent profile */
  updateTalentProfile?: Maybe<Scalars['ID']>;
  /** Upload talent avatar */
  uploadTalentAvatar?: Maybe<Avatar>;
  /** Upload talent documents */
  uploadTalentDocument?: Maybe<Array<Maybe<TalentDocument>>>;
  createChatMessage?: Maybe<ChatMessage>;
  /** Create new company user account */
  createCompanyUserProfile?: Maybe<User>;
  /** Update company user profile */
  updateCompanyUserProfile?: Maybe<User>;
  /** Upload user avatar */
  uploadUserAvatar?: Maybe<Avatar>;
  /** Request contract */
  requestOTContract: Scalars['Boolean'];
  deleteCompanyUserProfile?: Maybe<Scalars['Boolean']>;
  /** Create 'Talent by Talent' invitation */
  createTalentByTalentInvitation?: Maybe<Talent>;
  /** Create chief's program application. Return true in case of successful creation */
  createChiefsProgramApplication: Scalars['Boolean'];
  /** Create a job application */
  applyForJob: Scalars['Boolean'];
  /** Create a suggestion of the talent for a job */
  createJobSuggestion: Scalars['Boolean'];
  /** Create job (for company user flow) */
  createJob: Scalars['ID'];
  /** Update job (for company user flow) */
  updateJob: Job;
  saveMatchToShortList?: Maybe<Scalars['Boolean']>;
  deleteMatchFromShortList?: Maybe<Scalars['Boolean']>;
  inviteToJob?: Maybe<Scalars['Boolean']>;
  declineJobInvitation?: Maybe<Scalars['Boolean']>;
  /** Talent flow - save/delete job from favorite */
  saveJobToFavorites?: Maybe<Scalars['Boolean']>;
  deleteJobFromFavorites?: Maybe<Scalars['Boolean']>;
  /** Create invitation link */
  createInvitationLink?: Maybe<InvitationLink>;
  /** Send invitation by email */
  sendInvitationLinkByEmail?: Maybe<Scalars['Boolean']>;
  /** Create talent account from invitation link */
  createTalentAccountFromInvitationLink?: Maybe<Scalars['Boolean']>;
  /** Invite form for * (particular company, name is hidden for safety reason) - Deprecated */
  companyInviteFormSubmit: Scalars['Boolean'];
  /** Invite form for company's public invitation form */
  inviteFormSubmit: Scalars['Boolean'];
  createCompany?: Maybe<Company>;
  /** create new company and attach it to current company user */
  createCompanyForCurrentUser?: Maybe<Company>;
  updateCompany?: Maybe<Company>;
  /** Upload talent avatar */
  uploadCompanyLogo?: Maybe<CompanyLogo>;
  changeNotificationReadStatus: Scalars['Boolean'];
  deleteNotification: Scalars['Boolean'];
  currentUserNotificationsMarkAllAsRead: Scalars['Boolean'];
  updateNotification: Notification;
  /** Upload file */
  uploadFile?: Maybe<File>;
  uploadFileTest?: Maybe<File>;
  inviteCompanyUser?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateSkillArgs = {
  skill_type: SkillTypeEnum;
  name: Scalars['String'];
};


export type MutationCreateTalentProfileArgs = {
  user_id: Scalars['Int'];
  generalInfo: TalentGeneralInfoInput;
  email: Scalars['Email'];
  is_ot_pool?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateTalentProfileArgs = {
  talent_id: Scalars['ID'];
  avatar?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  general_info?: Maybe<TalentGeneralInfoInput>;
  account_info?: Maybe<TalentAccountVerificationInput>;
  work_history?: Maybe<Array<Maybe<WorkHistoryInput>>>;
  documents?: Maybe<Array<Maybe<Scalars['String']>>>;
  skills?: Maybe<Array<Maybe<Scalars['Int']>>>;
  companies?: Maybe<Array<Maybe<Scalars['Int']>>>;
  available_now?: Maybe<Scalars['Boolean']>;
  available_date?: Maybe<Scalars['DateTimeUtc']>;
  hours_per_week?: Maybe<Scalars['Int']>;
};


export type MutationUploadTalentAvatarArgs = {
  talent_id: Scalars['ID'];
  file: Scalars['Upload'];
};


export type MutationUploadTalentDocumentArgs = {
  talent_id: Scalars['ID'];
  files: Array<Scalars['Upload']>;
};


export type MutationCreateChatMessageArgs = {
  user_id: Scalars['ID'];
  talent_id: Scalars['ID'];
  message: Scalars['String'];
  parent_id?: Maybe<Scalars['ID']>;
};


export type MutationCreateCompanyUserProfileArgs = {
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email: Scalars['Email'];
  position?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};


export type MutationUpdateCompanyUserProfileArgs = {
  company_user_id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  position?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};


export type MutationUploadUserAvatarArgs = {
  user_id: Scalars['ID'];
  file: Scalars['Upload'];
};


export type MutationDeleteCompanyUserProfileArgs = {
  company_user_id: Scalars['ID'];
};


export type MutationCreateTalentByTalentInvitationArgs = {
  inviting_talent_id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['Email'];
};


export type MutationCreateChiefsProgramApplicationArgs = {
  talent_id: Scalars['ID'];
};


export type MutationApplyForJobArgs = {
  job_id: Scalars['ID'];
  rate?: Maybe<Scalars['Float']>;
  pitch?: Maybe<Scalars['String']>;
};


export type MutationCreateJobSuggestionArgs = {
  job_id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
};


export type MutationCreateJobArgs = {
  name: Scalars['String'];
  category_id?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
  pitch?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['DateTimeUtc']>;
  end_date?: Maybe<Scalars['DateTimeUtc']>;
  rate_min?: Maybe<Scalars['Float']>;
  rate_max?: Maybe<Scalars['Float']>;
  is_rate_negotiable?: Maybe<Scalars['Boolean']>;
  salary_min?: Maybe<Scalars['Float']>;
  salary_max?: Maybe<Scalars['Float']>;
  is_salary_negotiable?: Maybe<Scalars['Boolean']>;
  max_project_budget?: Maybe<Scalars['Float']>;
  finders_fee?: Maybe<Scalars['Float']>;
  campaign_owner_id: Scalars['ID'];
  campaign_start_date?: Maybe<Scalars['DateTimeUtc']>;
  campaign_end_date?: Maybe<Scalars['DateTimeUtc']>;
  campaign_duration: Scalars['Int'];
  campaign_talent_pool?: Maybe<Array<Maybe<SourceTypeEnum>>>;
  is_archived?: Maybe<Scalars['Boolean']>;
  skills?: Maybe<Array<Maybe<Scalars['Int']>>>;
  skills_required?: Maybe<Array<Maybe<Scalars['Int']>>>;
  hours_per_week?: Maybe<Scalars['Int']>;
  location_type?: Maybe<JobLocationTypeEnum>;
  is_remote_an_option?: Maybe<Scalars['Boolean']>;
  type?: Maybe<JobTypeEnum>;
  location?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  is_instant_campaign_start?: Maybe<Scalars['Boolean']>;
  is_draft?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateJobArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  pitch?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['DateTimeUtc']>;
  end_date?: Maybe<Scalars['DateTimeUtc']>;
  rate_min?: Maybe<Scalars['Float']>;
  rate_max?: Maybe<Scalars['Float']>;
  is_rate_negotiable?: Maybe<Scalars['Boolean']>;
  salary_min?: Maybe<Scalars['Float']>;
  salary_max?: Maybe<Scalars['Float']>;
  is_salary_negotiable?: Maybe<Scalars['Boolean']>;
  max_project_budget?: Maybe<Scalars['Float']>;
  finders_fee?: Maybe<Scalars['Float']>;
  campaign_owner_id?: Maybe<Scalars['ID']>;
  campaign_start_date?: Maybe<Scalars['DateTimeUtc']>;
  campaign_end_date?: Maybe<Scalars['DateTimeUtc']>;
  campaign_duration?: Maybe<Scalars['Int']>;
  campaign_talent_pool?: Maybe<Array<Maybe<SourceTypeEnum>>>;
  is_archived?: Maybe<Scalars['Boolean']>;
  skills?: Maybe<Array<Maybe<Scalars['Int']>>>;
  skills_required?: Maybe<Array<Maybe<Scalars['Int']>>>;
  hours_per_week?: Maybe<Scalars['Int']>;
  location_type?: Maybe<JobLocationTypeEnum>;
  is_remote_an_option?: Maybe<Scalars['Boolean']>;
  type?: Maybe<JobTypeEnum>;
  location?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  is_instant_campaign_start?: Maybe<Scalars['Boolean']>;
  is_draft?: Maybe<Scalars['Boolean']>;
};


export type MutationSaveMatchToShortListArgs = {
  match_id: Scalars['ID'];
};


export type MutationDeleteMatchFromShortListArgs = {
  match_id: Scalars['ID'];
};


export type MutationInviteToJobArgs = {
  talent_id: Scalars['ID'];
  job_id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};


export type MutationDeclineJobInvitationArgs = {
  job_invitation_id: Scalars['ID'];
};


export type MutationSaveJobToFavoritesArgs = {
  job_id: Scalars['ID'];
};


export type MutationDeleteJobFromFavoritesArgs = {
  job_id: Scalars['ID'];
};


export type MutationCreateInvitationLinkArgs = {
  inviting_talent_id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationSendInvitationLinkByEmailArgs = {
  invitation_link_id: Scalars['ID'];
  email: Scalars['Email'];
};


export type MutationCreateTalentAccountFromInvitationLinkArgs = {
  key: Scalars['String'];
  email: Scalars['Email'];
};


export type MutationCompanyInviteFormSubmitArgs = {
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email: Scalars['Email'];
};


export type MutationInviteFormSubmitArgs = {
  company_id: Scalars['ID'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email: Scalars['Email'];
};


export type MutationCreateCompanyArgs = {
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  type_of_activity?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};


export type MutationCreateCompanyForCurrentUserArgs = {
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  type_of_activity?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};


export type MutationUpdateCompanyArgs = {
  company_id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  type_of_activity?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};


export type MutationUploadCompanyLogoArgs = {
  company_id: Scalars['ID'];
  file: Scalars['Upload'];
};


export type MutationChangeNotificationReadStatusArgs = {
  notification_id: Scalars['ID'];
  new_status: Scalars['Boolean'];
};


export type MutationDeleteNotificationArgs = {
  notification_id: Scalars['ID'];
};


export type MutationUpdateNotificationArgs = {
  id: Scalars['ID'];
  is_read?: Maybe<Scalars['Boolean']>;
};


export type MutationUploadFileArgs = {
  owner_id?: Maybe<Scalars['ID']>;
  file_type: FileTypeEnum;
  file: Scalars['Upload'];
};


export type MutationUploadFileTestArgs = {
  file_type: FileTypeEnum;
  owner_id: Scalars['ID'];
  file: Scalars['Upload'];
};


export type MutationInviteCompanyUserArgs = {
  email: Scalars['Email'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  recipient_type: NotificationRecipientTypeEnum;
  recipient_id: Scalars['ID'];
  message: NotificationMessage;
  buttons?: Maybe<Array<Maybe<NotificationButton>>>;
  related_job_id?: Maybe<Scalars['ID']>;
  is_read: Scalars['Boolean'];
  created_at: Scalars['DateTime'];
};

export type NotificationActionInterface = {
  type?: Maybe<NotificationActionTypeEnum>;
};

export type NotificationActionRedirect = NotificationActionInterface & {
  __typename?: 'NotificationActionRedirect';
  type?: Maybe<NotificationActionTypeEnum>;
  target_route_type?: Maybe<RedirectTargeRouteTypeEnum>;
  target_route_id: Scalars['ID'];
};

export const NotificationActionTypeEnum = {
  Redirect: 'REDIRECT',
  Confirm: 'CONFIRM'
} as const;

export type NotificationActionTypeEnum = typeof NotificationActionTypeEnum[keyof typeof NotificationActionTypeEnum];
export type NotificationButton = {
  __typename?: 'NotificationButton';
  text: Scalars['String'];
  action: NotificationActionInterface;
};

export type NotificationMessage = {
  __typename?: 'NotificationMessage';
  template?: Maybe<Scalars['String']>;
  template_actions?: Maybe<Array<Maybe<NotificationTemplateAction>>>;
};

/** A paginated list of Notification items. */
export type NotificationPaginator = {
  __typename?: 'NotificationPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Notification items. */
  data: Array<Notification>;
};

export const NotificationRecipientTypeEnum = {
  Talent: 'TALENT',
  CompanyUser: 'COMPANY_USER'
} as const;

export type NotificationRecipientTypeEnum = typeof NotificationRecipientTypeEnum[keyof typeof NotificationRecipientTypeEnum];
export type NotificationTemplateAction = {
  __typename?: 'NotificationTemplateAction';
  key?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  action?: Maybe<NotificationActionInterface>;
};

export type OtContract = {
  __typename?: 'OTContract';
  id: Scalars['ID'];
  status: OtContractStatusEnum;
};

/** Status of the contract between company and Opentalent */
export const OtContractStatusEnum = {
  Unknown: 'UNKNOWN',
  Requested: 'REQUESTED',
  SentToCompany: 'SENT_TO_COMPANY',
  Signed: 'SIGNED'
} as const;

export type OtContractStatusEnum = typeof OtContractStatusEnum[keyof typeof OtContractStatusEnum];
/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>;
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /** Get skills by skill type */
  skills?: Maybe<SkillPaginator>;
  /** Get skill by id */
  skill?: Maybe<Skill>;
  /** Get skill by slug */
  skillBySlug?: Maybe<Skill>;
  /** Search skills by substring occurrence */
  skillSearch: Array<Skill>;
  /** Check if skill exists by name */
  skillExists?: Maybe<Skill>;
  /** Talent list */
  talents?: Maybe<TalentPaginator>;
  talent?: Maybe<Talent>;
  /** Talent categories */
  talentCategories?: Maybe<Array<Maybe<TalentCategory>>>;
  /** Talent sources */
  talentSources?: Maybe<Array<Maybe<TalentSource>>>;
  /** Talent skills */
  talentSkills?: Maybe<SkillPaginator>;
  /** Talent documents */
  talentDocuments?: Maybe<Array<Maybe<TalentDocument>>>;
  /** Talent working history */
  talentWorkHistory?: Maybe<Array<Maybe<TalentWorkHistory>>>;
  /** Check talent existance by email */
  checkTalentExistsByEmail: Scalars['Boolean'];
  /** Current talent */
  currentTalent: Talent;
  chatMessages?: Maybe<ChatMessagePaginator>;
  chatMessage?: Maybe<ChatMessage>;
  /** Current company user */
  currentCompanyUser: User;
  currentCompanyUserColleagues: Array<Maybe<User>>;
  /** Info for 'Invite new talent' page (talent invite talent) */
  talentInvitationsInfo?: Maybe<TalentInvitationsInfo>;
  generalAnalytics?: Maybe<Array<Maybe<DashboardAnalyticsItem>>>;
  countryAnalytics?: Maybe<Array<Maybe<DashboardAnalyticsItem>>>;
  categoryAnalytics?: Maybe<Array<Maybe<DashboardAnalyticsItem>>>;
  lastTalents?: Maybe<Array<Maybe<Talent>>>;
  currentTalentJobBoardSearch?: Maybe<JobPaginator>;
  /** List of Jobs in jobs board */
  jobs?: Maybe<JobPaginator>;
  job?: Maybe<Job>;
  currentCompanyUserJobBoardInfo: JobBoardInfo;
  jobInvitation?: Maybe<JobInvitation>;
  currentTalentJobApplicationByJobId?: Maybe<JobApplication>;
  availableJobsForTalent?: Maybe<Array<Maybe<AvailableJobForTalent>>>;
  /** Invitation Links belonging to current talent */
  currentTalentInvitationLinks?: Maybe<Array<Maybe<InvitationLink>>>;
  /** Public information for Invitation Link by public key */
  invitationLinkInfo?: Maybe<InvitationLinkInfo>;
  companies?: Maybe<CompanyPaginator>;
  company?: Maybe<Company>;
  currentUserNotifications?: Maybe<NotificationPaginator>;
  currentUserUnreadNotificationsCount?: Maybe<Scalars['Int']>;
  commonAppInfo?: Maybe<CommonAppInfo>;
};


export type QuerySkillsArgs = {
  skill_type?: Maybe<SkillTypeEnum>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QuerySkillArgs = {
  id: Scalars['ID'];
};


export type QuerySkillBySlugArgs = {
  slug: Scalars['String'];
};


export type QuerySkillSearchArgs = {
  search: Scalars['String'];
  skill_type?: Maybe<SkillTypeEnum>;
};


export type QuerySkillExistsArgs = {
  name: Scalars['String'];
  skill_type: SkillTypeEnum;
};


export type QueryTalentsArgs = {
  search?: Maybe<Scalars['String']>;
  category_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  skills_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  max_rate?: Maybe<Scalars['Float']>;
  min_rate?: Maybe<Scalars['Float']>;
  source_type?: Maybe<SourceTypeEnum>;
  is_active?: Maybe<Scalars['Boolean']>;
  available_now?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Array<TalentsOrderByOrderByClause>>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryTalentArgs = {
  id: Scalars['ID'];
};


export type QueryTalentSkillsArgs = {
  talent_id: Scalars['Int'];
  skill_type_id?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryTalentDocumentsArgs = {
  talent_id: Scalars['Int'];
};


export type QueryTalentWorkHistoryArgs = {
  talent_id: Scalars['Int'];
};


export type QueryCheckTalentExistsByEmailArgs = {
  talent_email: Scalars['Email'];
};


export type QueryChatMessagesArgs = {
  talent_id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryChatMessageArgs = {
  id: Scalars['ID'];
};


export type QueryTalentInvitationsInfoArgs = {
  inviting_talent_id: Scalars['ID'];
};


export type QueryCurrentTalentJobBoardSearchArgs = {
  skills?: Maybe<Array<Maybe<Scalars['Int']>>>;
  categories?: Maybe<Array<Maybe<Scalars['Int']>>>;
  rate_min?: Maybe<Scalars['Float']>;
  rate_max?: Maybe<Scalars['Float']>;
  search?: Maybe<Scalars['String']>;
  is_saved?: Maybe<Scalars['Boolean']>;
  is_applied?: Maybe<Scalars['Boolean']>;
  order_by?: Maybe<Array<CurrentTalentJobBoardSearchOrderByOrderByClause>>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryJobsArgs = {
  company_id?: Maybe<Scalars['ID']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_draft?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryJobArgs = {
  id: Scalars['ID'];
};


export type QueryJobInvitationArgs = {
  id: Scalars['ID'];
};


export type QueryCurrentTalentJobApplicationByJobIdArgs = {
  job_id: Scalars['ID'];
};


export type QueryAvailableJobsForTalentArgs = {
  talent_id: Scalars['ID'];
};


export type QueryInvitationLinkInfoArgs = {
  key: Scalars['String'];
};


export type QueryCompaniesArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCurrentUserNotificationsArgs = {
  is_read?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export const RedirectTargeRouteTypeEnum = {
  TalentJob: 'TALENT_JOB',
  TalentJobInvitation: 'TALENT_JOB_INVITATION',
  CompanyUserJob: 'COMPANY_USER_JOB'
} as const;

export type RedirectTargeRouteTypeEnum = typeof RedirectTargeRouteTypeEnum[keyof typeof RedirectTargeRouteTypeEnum];
/** Talent skill */
export type Skill = {
  __typename?: 'Skill';
  id: Scalars['ID'];
  skill_type: SkillTypeEnum;
  name: Scalars['String'];
  slug: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Skill items. */
export type SkillPaginator = {
  __typename?: 'SkillPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Skill items. */
  data: Array<Skill>;
};

/** Talent skill type */
export type SkillType = {
  __typename?: 'SkillType';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export const SkillTypeEnum = {
  Solutions: 'SOLUTIONS',
  HardSkills: 'HARD_SKILLS',
  SoftSkills: 'SOFT_SKILLS'
} as const;

export type SkillTypeEnum = typeof SkillTypeEnum[keyof typeof SkillTypeEnum];
/** The available directions for ordering a list of records. */
export const SortOrder = {
  /** Sort records in ascending order. */
  Asc: 'ASC',
  /** Sort records in descending order. */
  Desc: 'DESC'
} as const;

export type SortOrder = typeof SortOrder[keyof typeof SortOrder];
export const SourceTypeEnum = {
  Own: 'OWN',
  Opentalent: 'OPENTALENT'
} as const;

export type SourceTypeEnum = typeof SourceTypeEnum[keyof typeof SourceTypeEnum];
export type Subscription = {
  __typename?: 'Subscription';
  chatMessageCreated?: Maybe<ChatMessage>;
};

export type Talent = {
  __typename?: 'Talent';
  id: Scalars['ID'];
  email: Scalars['Email'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  recent_position_title?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  rate_min?: Maybe<Scalars['Float']>;
  rate_max?: Maybe<Scalars['Float']>;
  invited_by?: Maybe<TalentInvitedBy>;
  about?: Maybe<Scalars['String']>;
  avatar?: Maybe<Avatar>;
  documents?: Maybe<Array<Maybe<TalentDocument>>>;
  category?: Maybe<TalentCategory>;
  source?: Maybe<TalentSource>;
  talent_data?: Maybe<TalentData>;
  talent_work_history?: Maybe<Array<Maybe<TalentWorkHistory>>>;
  skills?: Maybe<SkillPaginator>;
  is_invitation_accepted?: Maybe<Scalars['Boolean']>;
  available_date?: Maybe<Scalars['DateTimeUtc']>;
  available_now?: Maybe<Scalars['Boolean']>;
  hours_per_week?: Maybe<Scalars['Int']>;
  is_ot_pool?: Maybe<Scalars['Boolean']>;
  companies: Array<Maybe<Company>>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};


export type TalentSkillsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

export type TalentAccountVerificationInput = {
  facebook_profile_link?: Maybe<Scalars['String']>;
  linkedin_profile_link?: Maybe<Scalars['String']>;
  upwork_profile_link?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  vat_number?: Maybe<Scalars['String']>;
};

export type TalentCategory = {
  __typename?: 'TalentCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type TalentData = {
  __typename?: 'TalentData';
  facebook_profile_link?: Maybe<Scalars['String']>;
  linkedin_profile_link?: Maybe<Scalars['String']>;
  upwork_profile_link?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  vat_number?: Maybe<Scalars['String']>;
};

export type TalentDocument = {
  __typename?: 'TalentDocument';
  title: Scalars['String'];
  document: Scalars['String'];
  size: Scalars['Int'];
  content_type: Scalars['String'];
  hash: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type TalentGeneralInfoInput = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  recent_position_title?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  rate_min?: Maybe<Scalars['Float']>;
  rate_max?: Maybe<Scalars['Float']>;
  talent_category_id?: Maybe<Scalars['ID']>;
  talent_source_id?: Maybe<Scalars['ID']>;
};

export const TalentInvitationTypeEnum = {
  InvitationTypeByCompany: 'INVITATION_TYPE_BY_COMPANY',
  InvitationTypeByTalent: 'INVITATION_TYPE_BY_TALENT'
} as const;

export type TalentInvitationTypeEnum = typeof TalentInvitationTypeEnum[keyof typeof TalentInvitationTypeEnum];
export type TalentInvitedBy = {
  __typename?: 'TalentInvitedBy';
  invitation_type?: Maybe<TalentInvitationTypeEnum>;
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
};

export type TalentJobConnection = {
  __typename?: 'TalentJobConnection';
  is_saved: Scalars['Boolean'];
  is_applied: Scalars['Boolean'];
};

/** A paginated list of Talent items. */
export type TalentPaginator = {
  __typename?: 'TalentPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Talent items. */
  data: Array<Talent>;
};

export type TalentSource = {
  __typename?: 'TalentSource';
  id: Scalars['ID'];
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type TalentWorkHistory = {
  __typename?: 'TalentWorkHistory';
  id: Scalars['ID'];
  position_title: Scalars['String'];
  company_name: Scalars['String'];
  worked_from?: Maybe<Scalars['DateTime']>;
  worked_to?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** Allows ordering a list of records. */
export type TalentsOrderByClause = {
  /** The column that is used for ordering. */
  column: TalentsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for the `orderBy` argument on the query `posts`. */
export const TalentsOrderByColumn = {
  TalentId: 'TALENT_ID',
  AvailableNow: 'AVAILABLE_NOW',
  IsInvitationAccepted: 'IS_INVITATION_ACCEPTED'
} as const;

export type TalentsOrderByColumn = typeof TalentsOrderByColumn[keyof typeof TalentsOrderByColumn];
/** Order by clause for the `orderBy` argument on the query `talents`. */
export type TalentsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: TalentsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Specify if you want to include or exclude trashed results from a query. */
export const Trashed = {
  /** Only return trashed results. */
  Only: 'ONLY',
  /** Return both trashed and non-trashed results. */
  With: 'WITH',
  /** Only return non-trashed results. */
  Without: 'WITHOUT'
} as const;

export type Trashed = typeof Trashed[keyof typeof Trashed];

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['Email'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  company?: Maybe<UserCompany>;
  avatar?: Maybe<Avatar>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type UserCompany = {
  __typename?: 'UserCompany';
  id: Scalars['ID'];
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  type_of_activity?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  has_contract: Scalars['Boolean'];
  contract?: Maybe<OtContract>;
};

export type WorkHistoryInput = {
  position_title: Scalars['String'];
  company_name: Scalars['String'];
  worked_from?: Maybe<Scalars['Date']>;
  worked_to?: Maybe<Scalars['Date']>;
};

export type TalentInvitationsInfo = {
  __typename?: 'talentInvitationsInfo';
  /** Talents who have already been invited */
  invited_talents?: Maybe<Array<Maybe<Talent>>>;
  /** Number of invitations left for talent to invite another talents */
  invitations_left: Scalars['Int'];
};
