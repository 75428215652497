import React from 'react';
import { Link } from 'react-router-dom';
import replaceString from 'react-string-replace';

import Typography from 'components/typography';
import {
  NotificationActionInterface,
  NotificationMessage as NotificationMessageType,
} from 'generated/graphql-types';

import { getActionLink } from './utils';

interface NotificationMessageProps {
  message: NotificationMessageType;
  handleChangeStatus: (status: boolean) => void;
}

const NotificationMessage = ({
  message,
  handleChangeStatus,
}: NotificationMessageProps) => {
  const finalMessage = (message?.template_actions || []).reduce<
    React.ReactNode[]
  >(
    (acc, action) => {
      if (action) {
        return replaceString(acc, `{${action.key}}`, () => {
          return (
            <Link
              key={action?.text}
              onClick={() => handleChangeStatus(true)}
              to={getActionLink(action.action as NotificationActionInterface)}
            >
              <Typography
                style={{ whiteSpace: 'nowrap' }}
                variant="body2"
                component="span"
                color="tertiary"
              >
                {action?.text}
              </Typography>
            </Link>
          );
        });
      }
      return acc;
    },
    [message?.template || ''],
  );

  return <>{finalMessage}</>;
};

export default NotificationMessage;
