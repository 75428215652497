import { gql } from '@apollo/client';

export default gql`
  mutation CreateCompanyUser(
    $first_name: String!
    $last_name: String!
    $email: Email!
    $avatar: String
    $position: String
  ) {
    createCompanyUserProfile(
      first_name: $first_name
      last_name: $last_name
      email: $email
      position: $position
      avatar: $avatar
    ) {
      id
    }
  }
`;
