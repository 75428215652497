import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Skeleton } from '@material-ui/lab';

import { DEFAULT_AVATAR } from 'consts/common';
import useMediaQueries from 'hooks/common/useMediaQueries';

import AccountMenu from './AccountMenu';
import { useAccountMenu } from './hooks';
import useStyles from './styles';

export interface AccountProps {
  avatar?: string;
  name?: string;
  position?: string;
  isLoading?: boolean;
}

export interface StylesAccountProps extends AccountProps {
  isSM: boolean;
}

const Account = (props: AccountProps) => {
  const { avatar, name = 'User', position, isLoading } = props;
  const { isSM } = useMediaQueries();
  const classes = useStyles({ ...props, isSM });
  const menuProps = useAccountMenu();

  return (
    <div className={classes.root}>
      <ListItem
        ref={menuProps.anchorRef as any}
        aria-controls={menuProps.isOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={menuProps.handleOpen}
        classes={{ root: classes.root }}
        button
      >
        <ListItemAvatar classes={{ root: classes.itemListAvatar }}>
          {isLoading ? (
            <Skeleton
              className={classes.skeleton}
              variant="circle"
              width={40}
              height={40}
            />
          ) : (
            <Avatar alt="Avatar" src={avatar || DEFAULT_AVATAR} />
          )}
        </ListItemAvatar>
        {!isSM && (
          <ListItemText
            classes={{
              multiline: classes.textMultiline,
              secondary: classes.positionText,
            }}
            primary={
              isLoading ? (
                <Skeleton
                  className={classes.skeleton}
                  height={20}
                  width="50px"
                />
              ) : (
                name
              )
            }
            secondary={
              isLoading ? (
                <Skeleton
                  className={classes.skeleton}
                  height={20}
                  width="50px"
                />
              ) : (
                position
              )
            }
          />
        )}
      </ListItem>
      <AccountMenu {...menuProps} user={{ avatar, name }} />
    </div>
  );
};

export default Account;
