/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-plusplus */
import { InMemoryCache } from '@apollo/client';

import { QueryCurrentUserNotificationsArgs } from 'generated/graphql-types';

const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Talent: {
      fields: {
        avatar: {
          merge(existing = {}, incoming = {}) {
            return { ...existing, ...incoming };
          },
        },
      },
    },
    Query: {
      fields: {
        chatMessages: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = { data: [] }, incoming) {
            const currentData =
              incoming?.paginatorInfo?.currentPage === 1
                ? incoming?.data
                : existing?.data?.concat(...(incoming?.data ?? []));
            return {
              paginatorInfo: incoming?.paginatorInfo,
              data: currentData,
            };
          },
        },
        talents: {
          keyArgs: false,
          merge(_, incoming) {
            return {
              paginatorInfo: incoming?.paginatorInfo,
              data: incoming?.data ?? [],
            };
          },
        },
        currentCompanyUser: {
          keyArgs: false,
          merge(_, incoming) {
            return incoming;
          },
        },
        currentUserNotifications: {
          keyArgs: [],
          merge(existing, incoming, { args }) {
            const { page = 1, first = 20 } =
              args as QueryCurrentUserNotificationsArgs;
            const isFirstPage = incoming?.paginatorInfo?.currentPage === 1;

            // const offset = page * first - 1;
            // const merged = existing?.data ? existing.data.slice(0) : [];
            // for (let i = 0; i < incoming.data.length; ++i) {
            //   merged[offset + i] = incoming.data[i];
            // }

            return {
              ...incoming,
              paginatorInfo: incoming.paginatorInfo,
              // data: merged,

              data: isFirstPage
                ? incoming.data
                : [...(existing?.data || []), ...incoming.data],
            };
          },
        },
      },
    },
  },
});

export default cache;
