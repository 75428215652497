import React from 'react';
import { RouteProps } from 'react-router-dom';

import PageLayout from 'components/layout/page-layout';

const CompanySettings = React.lazy(() => import('./CompanySettings'));

const LazyCreateProfile = (props: RouteProps) => {
  return (
    <React.Suspense
      fallback={
        <PageLayout
          isLoading
          headerProps={{ isLoading: true }}
          drawerProps={{ isLoading: true }}
        />
      }
    >
      <CompanySettings {...props} />
    </React.Suspense>
  );
};

export default LazyCreateProfile;
