import deepmerge from 'deepmerge';

import { createMuiTheme } from '@material-ui/core/styles';

import { themeParams } from 'themes/default';

const theme = createMuiTheme(
  deepmerge(themeParams, {
    palette: {
      primary: {
        main: '#F2FF88',
        light: '#FFFF8A',
        dark: '#D1D100',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      secondary: {
        main: '#FFF',
        dark: '#FFF',
        light: '#FFF',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.54)',
        disabled: 'rgba(0, 0, 0, 0.38)',
        hint: 'rgba(0, 0, 0, 0.54)',
      },
    },
    overrides: {
      MuiListItemText: {
        secondary: {
          color: 'inherit',
        },
        primary: {
          color: 'inherit',
        },
      },
      MuiDrawer: {
        paperAnchorDockedLeft: {
          borderRight: 'none',
        },
      },
    },
  }),
);

export default theme;
export type Theme = typeof theme;
