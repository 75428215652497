import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

import * as AuthUtils from 'auth0/utils';
import { Query } from 'generated/graphql-types';
import {
  GetCompanyUserLayoutData,
  GetTalentLayoutData,
} from 'graphql/common/queries';
import { ANONYMOUS_LOCATIONS } from 'routes';

import { useAuth0 } from './useAuth0';

const useCurrentUserRequest = () => {
  const { user: auth0User, isLoading } = useAuth0();
  const isTalent = AuthUtils.isTalent(auth0User);
  const isNotAnonymousRoute = !ANONYMOUS_LOCATIONS.includes(
    window.location.pathname,
  );
  const [getUser, { data, loading }] = useLazyQuery<Query>(
    isTalent ? GetTalentLayoutData : GetCompanyUserLayoutData,
  );

  useEffect(() => {
    if (auth0User && !data && isNotAnonymousRoute) {
      getUser();
    }
  }, [auth0User, data, getUser, isNotAnonymousRoute]);

  const user = data?.[isTalent ? 'currentTalent' : 'currentCompanyUser'];

  return { data, user, isLoading: isLoading || loading };
};

export default useCurrentUserRequest;
