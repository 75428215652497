import { gql } from '@apollo/client';

export default gql`
  mutation UpdateTalentProfile(
    $talent_id: ID!
    $avatar: String
    $about: String
    $general_info: TalentGeneralInfoInput
    $account_info: TalentAccountVerificationInput
    $work_history: [WorkHistoryInput]
    $documents: [String]
    $skills: [Int]!
    $available_now: Boolean
    $available_date: DateTimeUtc
    $hours_per_week: Int
    $companies: [Int]
  ) {
    updateTalentProfile(
      talent_id: $talent_id
      avatar: $avatar
      about: $about
      general_info: $general_info
      account_info: $account_info
      work_history: $work_history
      documents: $documents
      skills: $skills
      available_now: $available_now
      available_date: $available_date
      hours_per_week: $hours_per_week
      companies: $companies
    )
  }
`;
