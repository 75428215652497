import { gql } from '@apollo/client';

export const GET_INVITATION_INFO = gql`
  query GetTalentInvitationsInfo($inviting_talent_id: ID!) {
    talentInvitationsInfo(inviting_talent_id: $inviting_talent_id) {
      invitations_left
      invited_talents {
        id
        email
        first_name
        last_name
        is_invitation_accepted
        avatar {
          avatar
        }
      }
    }
  }
`;

export const GET_CURRENT_TALENT_ID = gql`
  query GetCurrentTalent {
    currentTalent {
      id
      is_invitation_accepted
    }
  }
`;

export const INVITE_TALENT = gql`
  mutation CreateTalentByTalentInvitation(
    $inviting_talent_id: ID!
    $name: String!
    $email: Email!
  ) {
    createTalentByTalentInvitation(
      inviting_talent_id: $inviting_talent_id
      name: $name
      email: $email
    ) {
      id
    }
  }
`;
