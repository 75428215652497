import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { ReactComponent as WorkforceIcon } from 'assets/icons/workforce.svg';

import DrawerList from '../drawer-list';
import useStyles from './styles';

export interface DrawerListItemProps {
  text: string;
  link?: string;
  Icon?: React.ElementType;
  isActive?: boolean;
  badge?: number | string;
  items?: DrawerListItemProps[];
  isExternal?: boolean;
  testName?: string;
}

const DrawerListItem = (props: DrawerListItemProps) => {
  const {
    text,
    link,
    Icon = WorkforceIcon,
    badge,
    isActive,
    items,
    isExternal,
    testName,
  } = props;
  const matchRoute = useRouteMatch({
    path: link,
    exact: true,
  });
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const classes = useStyles({ ...props, isActive: isActive || !!matchRoute });
  const Caret = collapsed ? ExpandMore : ExpandLess;
  // eslint-disable-next-line no-nested-ternary
  const Wrapper = (isExternal ? 'a' : link ? Link : 'span') as any;

  return (
    <>
      <Wrapper
        to={link as string}
        {...(isExternal && { href: link, target: '_blank' })}
      >
        <ListItem
          onClick={() => setCollapsed((s) => !s)}
          classes={{ root: classes.listItem }}
          button
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <Icon color="inherit" data-test={testName} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            classes={{ root: classes.text }}
            primary={text}
          />
          {badge && (
            <Badge
              classes={{ badge: classes.badge }}
              badgeContent={badge}
              color="primary"
            />
          )}
          {items?.length && <Caret classes={{ root: classes.caret }} />}
        </ListItem>
      </Wrapper>
      {items?.length && (
        <Collapse in={!collapsed} timeout="auto">
          <Box paddingLeft="20px">
            <DrawerList disablePadding items={items} />
          </Box>
        </Collapse>
      )}
    </>
  );
};

export default DrawerListItem;
