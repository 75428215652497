import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from 'hooks/auth/useAuth0';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import useMixPanel from 'hooks/common/useMixPanel';
import { pathManager } from 'routes';

export const useAuthRedirect = () => {
  const { user: auth0User, isLoading } = useAuth0();
  const { data, isCompany, isTalent } = useCurrentUser();
  const history = useHistory();
  const mixPanel = useMixPanel();
  const talent = data?.currentTalent;
  const from = window.localStorage.getItem('authFrom');

  const checkRedirect = useCallback(async () => {
    if (!auth0User) {
      // eslint-disable-next-line no-console
      console.error('Not authenticated');
      history.push(pathManager.auth.generatePath());
      return;
    }
    if (from) {
      history.push(from);
      window.localStorage.setItem('authFrom', '');
      return;
    }

    if (isCompany) {
      history.push(pathManager.company.jobBoard.generatePath());
    } else if (isTalent) {
      const isAccepted = talent?.is_invitation_accepted;
      const routeToPush = isAccepted
        ? pathManager.talent.jobBoard.generatePath()
        : pathManager.talent.onboarding.intro.generatePath();
      history.push(routeToPush);

      if (!isAccepted) {
        mixPanel.track(
          'User signed-up to account from invitation letter or link',
        );
      }
    }
  }, [
    auth0User,
    from,
    history,
    isCompany,
    isTalent,
    mixPanel,
    talent?.is_invitation_accepted,
  ]);

  useEffect(() => {
    checkRedirect();
  }, [checkRedirect, data]);

  return { isLoading };
};
