import React from 'react';

import PageLayout from 'components/layout/page-layout';
import { Talent, User } from 'generated/graphql-types';

import { useAuthRedirect } from './hooks';

interface Auth0 {
  user?: Talent | User;
}

const Auth0 = ({ user }: Auth0) => {
  useAuthRedirect();

  return (
    <PageLayout
      isLoading
      headerProps={{ isLoading: true }}
      drawerProps={{ isLoading: true }}
    />
  );
};

export default Auth0;
