import { gql } from '@apollo/client';

export default gql`
  fragment FullTalent on Talent {
    first_name
    last_name
    address
    location
    recent_position_title
    about
    id
    rate_min
    rate_max
    is_invitation_accepted
    email
    available_date
    hours_per_week
    available_now
    is_ot_pool
    companies {
      id
      name
    }
    category {
      id
      name
      slug
      created_at
      updated_at
    }
    source {
      id
      name
      logo
      created_at
      updated_at
    }
    avatar {
      avatar
      hash
    }
    documents {
      title
      document
      size
      content_type
      hash
      created_at
      updated_at
    }
    talent_data {
      facebook_profile_link
      linkedin_profile_link
      upwork_profile_link
      phone
      vat_number
    }
    invited_by {
      company_name
      first_name
      last_name
      invitation_type
      id
    }
    talent_work_history: talent_work_history {
      id
      position_title
      company_name
      worked_from
      worked_to
      created_at
      updated_at
    }
    documents {
      title
      document
      size
      content_type
      hash
      created_at
      updated_at
    }
    skills(first: 50) {
      data {
        id
        skill_type: skill_type
        name
      }
    }
  }
`;
