import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import PageLayout from 'components/layout/page-layout';

const Page = React.lazy(() => import('./Users'));

const LazyCreateProfile = (props: RouteComponentProps) => {
  return (
    <React.Suspense
      fallback={
        <PageLayout
          isLoading
          headerProps={{ isLoading: true }}
          drawerProps={{ isLoading: true }}
        />
      }
    >
      <Page {...props} />
    </React.Suspense>
  );
};

export default LazyCreateProfile;
