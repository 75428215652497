import React, { Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import * as AuthUtils from 'auth0/utils';
import PageLayout from 'components/layout/page-layout';
import { useAuth0 } from 'hooks/auth/useAuth0';
import useCurrentUserRequest from 'hooks/auth/useCurrentUserRequest';
import Auth0 from 'screens/auth/Auth0';
import Logout from 'screens/auth/Logout';
import QuickAuth from 'screens/auth/QuickAuth';
import ChooseNewJobType from 'screens/company-user/choose-new-job-type';
import CreateProfile from 'screens/company-user/create-profile';
import Dashboard from 'screens/company-user/dashboard';
import CompanyJob from 'screens/company-user/job';
import CompanyJobBoard from 'screens/company-user/job-board';
import JobForm from 'screens/company-user/job-form';
import CompanyOnboarding from 'screens/company-user/onboarding';
import CompanySettings from 'screens/company-user/settings';
import Users from 'screens/company-user/users';
import Workforce from 'screens/company-user/workforce';
import CorpLanding from 'screens/landings/corp-landing';
import Landing from 'screens/landings/landing';
import NotFound from 'screens/not-found';
import Notifications from 'screens/notifications';
import LazyProfile from 'screens/profile';
import EditProfile from 'screens/talent/edit-profile';
import Groups from 'screens/talent/groups';
import Invitation from 'screens/talent/invitation';
import InviteByTalent from 'screens/talent/invite-by-talent';
import JobApply from 'screens/talent/job-apply';
import TalentJobBoardV2 from 'screens/talent/job-board-v2';
import TalentJobInvitation from 'screens/talent/job-invitation';
import MarkedJobs from 'screens/talent/marked-jobs';
import TalentOnboarding from 'screens/talent/onboarding';

import PrivateRoute from './PrivateRoute';
import { pathManager } from './consts';

const Router = () => {
  const { user: auth0User, isAuthenticated } = useAuth0();
  const isCompany = isAuthenticated && AuthUtils.isCompany(auth0User);
  const { isLoading, user, data } = useCurrentUserRequest();
  const isLoadingFinal = isLoading || (auth0User && !user);
  const needRedirectToClientOnboarding =
    isCompany && !data?.currentCompanyUser?.company;

  return (
    <BrowserRouter>
      <Suspense
        fallback={<PageLayout isLoading headerProps={{ isLoading: true }} />}
      >
        <Switch>
          <Route
            exact
            component={Logout}
            path={pathManager.logout.getRoute()}
          />

          <Route path={pathManager.abnamro.getRoute()} component={Landing} />
          <Route
            path={pathManager.corpLanding.getRoute()}
            component={CorpLanding}
          />

          {isLoadingFinal && (
            <PageLayout isLoading headerProps={{ isLoading: true }} />
          )}
          <Route
            exact
            component={QuickAuth}
            path={pathManager.quickAuth.getRoute()}
          />
          <Route
            exact
            component={QuickAuth}
            path={pathManager.auth.getRoute()}
          />
          <Route
            exact
            component={Auth0}
            path={pathManager.auth0.getRoute()}
            render={() => <Auth0 user={user} />}
          />
          <Route
            path={pathManager.invitation.getRoute()}
            component={Invitation}
          />

          {!user && (
            <Redirect
              to={{
                pathname: pathManager.quickAuth.getRoute(),
                state: { from: window.location.pathname },
              }}
            />
          )}

          <Route
            path={pathManager.notifications.getRoute()}
            component={Notifications}
          />

          {/* Company routes */}
          <PrivateRoute
            component={CompanyOnboarding}
            predicate={AuthUtils.isCompany}
            path={pathManager.company.onboarding.main.getRoute()}
          />
          {needRedirectToClientOnboarding && (
            <Redirect
              to={{
                pathname: pathManager.company.onboarding.main.getRoute(),
                state: { from: window.location.pathname },
              }}
            />
          )}
          <PrivateRoute
            exact
            predicate={AuthUtils.isCompany}
            path={pathManager.company.createProfile.getRoute()}
            component={CreateProfile}
          />
          <PrivateRoute
            exact
            predicate={AuthUtils.isCompany}
            component={LazyProfile}
            path={pathManager.company.talentProfile.getRoute()}
          />
          <PrivateRoute
            component={CompanySettings}
            exact
            predicate={AuthUtils.isCompany}
            path={pathManager.company.settings.main.getRoute()}
          />
          <PrivateRoute
            component={Users}
            exact
            predicate={AuthUtils.isCompany}
            path={[
              pathManager.company.users.edit.getRoute(),
              pathManager.company.users.create.getRoute(),
            ]}
          />
          <PrivateRoute
            component={CompanyJobBoard}
            exact
            predicate={AuthUtils.isCompany}
            path={pathManager.company.jobBoard.getRoute()}
          />
          <PrivateRoute
            component={JobForm}
            exact
            predicate={AuthUtils.isCompany}
            path={[
              pathManager.company.editJob.getRoute(),
              pathManager.company.newJob.getRoute(),
              pathManager.company.duplicateJob.getRoute(),
            ]}
          />
          <PrivateRoute
            component={ChooseNewJobType}
            exact
            predicate={AuthUtils.isCompany}
            path={pathManager.company.chooseNewJobType.getRoute()}
          />
          <PrivateRoute
            exact
            component={CompanyJob}
            predicate={AuthUtils.isCompany}
            path={pathManager.company.job.getRoute()}
          />
          <PrivateRoute
            exact
            predicate={AuthUtils.isCompany}
            component={Workforce}
            path={pathManager.company.workforce.getRoute()}
          />
          <PrivateRoute
            component={Dashboard}
            exact
            predicate={AuthUtils.isCompany}
            path={pathManager.company.dashboard.getRoute()}
          />

          {/* Talent routes */}
          <PrivateRoute
            predicate={AuthUtils.isTalent}
            exact
            component={TalentJobBoardV2}
            path={pathManager.talent.jobBoard.getRoute()}
          />
          <PrivateRoute
            component={JobApply}
            predicate={AuthUtils.isTalent}
            path={pathManager.talent.jobApply.getRoute()}
          />

          <PrivateRoute
            component={MarkedJobs}
            predicate={AuthUtils.isTalent}
            path={[pathManager.talent.markedJobs.getRoute()]}
          />

          <PrivateRoute
            component={TalentJobInvitation}
            predicate={AuthUtils.isTalent}
            path={pathManager.talent.jobInvitation.getRoute()}
          />
          <PrivateRoute
            exact
            predicate={AuthUtils.isTalent}
            path={pathManager.talent.editProfile.getRoute()}
            component={EditProfile}
          />
          <PrivateRoute
            component={LazyProfile}
            predicate={AuthUtils.isTalent}
            exact
            path={pathManager.talent.profile.getRoute()}
          />
          <PrivateRoute
            component={InviteByTalent}
            predicate={AuthUtils.isTalent}
            exact
            path={pathManager.talent.invite.getRoute()}
          />
          <PrivateRoute
            component={TalentOnboarding}
            predicate={AuthUtils.isTalent}
            path={pathManager.talent.onboarding.main.getRoute()}
          />
          <PrivateRoute
            component={Groups}
            predicate={AuthUtils.isTalent}
            path={pathManager.talent.groups.getRoute()}
          />

          {/* Else */}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
