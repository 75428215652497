import { gql } from '@apollo/client';

import FULL_USER_FRAGMENT from 'graphql/fragments/companyUser/fullCompanyUserFrag';
import GET_NOTIFICATIONS_FRAG from 'graphql/fragments/notifications/currentNotificationsFrag';
import FULL_TALENT_FRAGMENT from 'graphql/fragments/talent/fullTalentFragment';

export const GetCompanyUserLayoutData = gql`
  ${FULL_USER_FRAGMENT}
  ${GET_NOTIFICATIONS_FRAG}
  query CompanyUserLayout {
    currentCompanyUser {
      ...FullUser
    }
    currentUserUnreadNotificationsCount
    currentUserNotifications(first: 20, page: 1) {
      ...CurrentNotifications
    }
    jobs {
      paginatorInfo {
        total
      }
    }
  }
`;

export const GetTalentLayoutData = gql`
  ${FULL_TALENT_FRAGMENT}
  ${GET_NOTIFICATIONS_FRAG}
  query TalentUserLayout {
    currentUserNotifications(first: 20, page: 1) {
      ...CurrentNotifications
    }
    currentUserUnreadNotificationsCount
    currentTalent {
      ...FullTalent
    }
  }
`;
