import React from 'react';

import PageLayout from 'components/layout/page-layout';

import { PageProps } from './types';

const MarkedJobs = React.lazy(() => import('./MarkedJobs'));

const AppliedJobsLazy = (props: PageProps) => (
  <React.Suspense
    fallback={<PageLayout isLoading headerProps={{ isLoading: true }} />}
  >
    <MarkedJobs {...props} />
  </React.Suspense>
);

export default AppliedJobsLazy;
