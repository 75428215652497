import React from 'react';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';

import Typography from 'components/typography';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { pathManager } from 'routes';

interface AccountMenuProps {
  isOpen: boolean;
  anchorRef: any;
  handleClose: VoidFunction;
  menuId: string;
  user?: {
    avatar?: string;
    name: string;
  };
}

const AccountMenu = (props: AccountMenuProps) => {
  const { isOpen, anchorRef, handleClose, menuId, user } = props;
  const { isTalent } = useCurrentUser();

  return (
    <Popper
      placement="bottom-end"
      open={isOpen}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      style={{ width: 200 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'right top' : 'right top',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                {user && (
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    wrap="nowrap"
                    component={Box}
                    px={4}
                    pt={6}
                    pb={2}
                    spacing={2}
                    style={{ alignItems: 'center' }}
                  >
                    <Grid item>
                      <Avatar
                        style={{ width: 48, height: 48 }}
                        src={user.avatar}
                        alt={user.name}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary">
                        {user.name}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <MenuList autoFocusItem={isOpen} id={menuId}>
                  {!isTalent && (
                    <Link to={pathManager.company.settings.main.generatePath()}>
                      <MenuItem>
                        <ListItemIcon>
                          <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          color="#fff"
                          primary="Settings"
                        />
                      </MenuItem>
                    </Link>
                  )}
                  <Link to={pathManager.logout.generatePath()}>
                    <MenuItem>
                      <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        color="#fff"
                        primary="Logout"
                      />
                    </MenuItem>
                  </Link>
                </MenuList>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default AccountMenu;
