import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import PageLayout from 'components/layout/page-layout';

const InviteByTalent = React.lazy(() => import('./InviteByTalent'));

const InviteByTalentLazy = (props: RouteComponentProps) => (
  <React.Suspense
    fallback={<PageLayout isLoading headerProps={{ isLoading: true }} />}
  >
    <InviteByTalent {...props} />
  </React.Suspense>
);

export default InviteByTalentLazy;
