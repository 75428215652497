import cn from 'classnames';
import React from 'react';

import { makeStyles } from '@material-ui/core';
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from '@material-ui/core/Button';

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  onClick?: VoidFunction;
  color?: MuiButtonProps['color'] | 'tertiary' | 'error';
}

const useStyles = makeStyles((theme) => ({
  color_tertiary: {
    color: theme.palette.tertiary.main,
  },
  color_error: {
    color: `${theme.palette.error.main} !important`,
    background: theme.palette.warning.light,
  },
}));

const getColor = (color: ButtonProps['color']): any =>
  color && ['tertiary', 'error'].includes(color) ? undefined : color;

const Button = ({ color, ...props }: ButtonProps) => {
  const classes = useStyles();
  return (
    <MuiButton
      {...props}
      color={getColor(color)}
      className={cn(props.className, {
        [classes.color_tertiary]: color === 'tertiary',
        [classes.color_error]: color === 'error',
      })}
    />
  );
};

export default Button;
