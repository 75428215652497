import { format, parseISO } from 'date-fns';
import React from 'react';

import { Grid, Box, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Typography from 'components/typography';
import { Notification } from 'generated/graphql-types';

import NotificationActions from './NotificationActions';
import NotificationMessage from './NotificationMessage';

interface NotificationItemProps {
  notification: Notification;
  changeStatus: ({
    status,
    notification,
  }: {
    status: boolean;
    notification: Notification;
    notify?: boolean;
  }) => void;
  deleteNotification: (notification: Notification) => void;
  isLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
  },
  dot: {
    '& svg': {
      fontSize: 14,
      color: ({ notification }: { notification: Notification }) =>
        notification.is_read
          ? theme.palette.grey[400]
          : theme.palette.tertiary.main,
    },
  },
  content: {
    flexGrow: 1,
  },
  actions: {},
}));

const NotificationItem = ({
  notification,
  isLoading,
  changeStatus,
  deleteNotification,
}: NotificationItemProps) => {
  const classes = useStyles({ notification });

  return (
    <Box
      data-test="header-notification-item"
      className={classes.root}
      p={2}
      mb={1}
    >
      <Grid wrap="nowrap" spacing={2} container alignItems="center">
        <Grid className={classes.dot} item>
          <Tooltip title={`Mark as${notification?.is_read ? '' : ' not'} read`}>
            <div>
              <IconButton
                onClick={() =>
                  changeStatus({
                    status: !notification.is_read,
                    notification,
                    notify: true,
                  })
                }
                disabled={isLoading}
                size="small"
              >
                <FiberManualRecordIcon fontSize="small" />
              </IconButton>
            </div>
          </Tooltip>
        </Grid>
        <Grid className={classes.content} item>
          <Typography variant="body2">
            <NotificationMessage
              handleChangeStatus={() =>
                changeStatus({ status: true, notification })
              }
              message={notification.message}
            />
          </Typography>
          <Box pt={1}>
            <Typography variant="caption" color="textSecondary">
              {format(parseISO(notification.created_at), 'MMMM dd')}
            </Typography>
          </Box>
        </Grid>
        <Grid className={classes.actions} item>
          <NotificationActions
            handleChangeStatus={() =>
              changeStatus({ status: true, notification })
            }
            deleteNotification={() => deleteNotification(notification)}
            notification={notification}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotificationItem;
