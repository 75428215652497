import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Theme } from 'themes/default';

import { DRAWER_WIDTH } from '../consts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
    paper: {
      paddingTop: theme.spacing(6),
      background: theme.palette.secondary.main,
      width: DRAWER_WIDTH,
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      flexDirection: 'column',
    },
    skeleton: {
      backgroundColor: 'white',
      opacity: 0.1,
      margin: `0 ${theme.spacing(3)}px`,
    },
    contactBtn: {
      letterSpacing: 0,
    },
  }),
);

export default useStyles;
