/* eslint-disable no-template-curly-in-string, func-names */
// import { isPast } from 'date-fns';
import * as yup from 'yup';

import errors from 'consts/validationErrors';

export const setYupLocale = () =>
  yup.setLocale({
    mixed: {
      default: '${path} is invalid',
      required: errors.required,
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
    },
    string: {
      length: 'Must be exactly ${length} characters',
      min: 'Must be at least ${min} characters',
      max: 'Must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: 'Must be a valid email',
      url: '${path} must be a valid URL',
      uuid: '${path} must be a valid UUID',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
    },
    number: {
      min: 'Must be greater than or equal to ${min}',
      max: 'Must be less than or equal to ${max}',
      lessThan: 'Must be less than ${less}',
      moreThan: 'Must be greater than ${more}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
    },
    date: {},
    boolean: {},
    object: {},
    array: {
      min: 'Field must have at least ${min} items',
    },
  });

// yup.addMethod(yup.date, 'isPresent', function (formats, parseStrict) {
//   return (this as any).test({
//     name: 'isPresentDate',
//     message: 'Must be present date',
//     test: (value: Date | undefined) =>
//       this.isValid(value) && !isPast(value as Date),
//   });
// });

// declare module 'yup' {
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   interface DateSchema {
//     isPresent(format: string): DateSchema;
//   }
// }
