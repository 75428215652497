import defaultAvatarPath from 'assets/images/default-avatar.svg';
import { OptionType } from 'components/form/select';
import {
  JobOrderByColumn,
  JobLocationTypeEnum,
  SkillTypeEnum,
  JobTypeEnum,
} from 'generated/graphql-types';

export const DEFAULT_AVATAR = defaultAvatarPath;
export const COUNTRY_OPTIONS = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'United Kingdom',
].map((country) => ({ value: country, text: country }));

export const SKILL_TYPES_MAP = {
  [SkillTypeEnum.HardSkills]: 'Hard skills',
  [SkillTypeEnum.Solutions]: 'Solutions',
  [SkillTypeEnum.SoftSkills]: 'Soft skills',
};

export const JOB_TYPE_MAP = {
  [JobLocationTypeEnum.OnSite]: 'On site',
  [JobLocationTypeEnum.Remote]: 'Remote',
};

export const JOB_ORDER_BY_COLUMN_MAP = {
  [JobOrderByColumn.CampaignStart]: 'Latest campaign',
  [JobOrderByColumn.RateMax]: 'Highest rate',
};
export const JOB_ORDER_BY_COLUMN_OPTIONS: OptionType[] = Object.entries(
  JOB_ORDER_BY_COLUMN_MAP,
).map(([value, text]) => ({ value, text }));

export const JOB_TYPES_LABELS_MAP = {
  [JobTypeEnum.Freelance]: 'Freelance job',
  [JobTypeEnum.Permanent]: 'Permanent role',
  [JobTypeEnum.Project]: 'Project',
};
