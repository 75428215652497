import { gql } from '@apollo/client';

export default gql`
  mutation UpdateCompanyUser(
    $company_user_id: ID!
    $first_name: String
    $last_name: String
    $email: Email
    $position: String
    $avatar: String
  ) {
    updateCompanyUserProfile(
      company_user_id: $company_user_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      position: $position
      avatar: $avatar
    ) {
      id
    }
  }
`;
