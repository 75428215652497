import { gql } from '@apollo/client';

const GET_NOTIFICATIONS_FRAG = gql`
  fragment CurrentNotifications on NotificationPaginator {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
    data {
      id
      recipient_type
      recipient_id
      buttons {
        text
        action {
          type
          ... on NotificationActionRedirect {
            target_route_type
            target_route_id
          }
        }
      }
      related_job_id
      is_read
      created_at
      message {
        template
        template_actions {
          key
          text
          action {
            type
            ... on NotificationActionRedirect {
              target_route_type
              target_route_id
            }
          }
        }
      }
    }
  }
`;

export default GET_NOTIFICATIONS_FRAG;
