import React from 'react';
import { RouteProps } from 'react-router-dom';

import { Box, CircularProgress } from '@material-ui/core';

const Landing = React.lazy(() => import('./CorpLanding'));

const LazyLanding = (props: RouteProps) => {
  return (
    <React.Suspense
      fallback={
        <Box
          width="100vw"
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      }
    >
      <Landing {...props} />
    </React.Suspense>
  );
};

export default LazyLanding;
