import React from 'react';

import PageLayout from 'components/layout/page-layout';

import { PageProps } from './types';

const JobForm = React.lazy(() => import('./JobForm'));

const InviteByTalentLazy = (props: PageProps) => (
  <React.Suspense
    fallback={<PageLayout isLoading headerProps={{ isLoading: true }} />}
  >
    <JobForm {...props} />
  </React.Suspense>
);

export default InviteByTalentLazy;
