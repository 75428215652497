import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Theme } from 'themes/default';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.grey[500],
    },
    badge: {
      minWidth: 12,
      height: 14,
      width: 14,
      fontSize: 9,
      padding: '0 4px',
    },
    text: {
      color: theme.palette.text.primary,
      textTransform: 'none',
      textIndent: theme.spacing(2),
    },
    skeleton: {
      backgroundColor: 'white',
      opacity: 0.1,
    },
  }),
);

export default useStyles;
