import React, { PropsWithChildren } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { UserInterface } from 'auth0/types';
import { useAuth0 } from 'hooks/auth/useAuth0';

import { pathManager } from './consts';

type PrivateRouteProps = PropsWithChildren<{
  predicate: (u: UserInterface) => boolean;
  component?: React.ReactNode;
}> &
  RouteProps;

const PrivateRoute = ({
  predicate,
  component,
  render,
  ...rest
}: PrivateRouteProps) => {
  const { user } = useAuth0();
  const RouteComponent = component as React.ComponentType<any>;
  return (
    <Route
      {...rest}
      render={(props) => {
        return predicate(user) ? (
          (render && render(props)) || <RouteComponent {...props} />
        ) : (
          <Redirect
            to={{
              pathname: pathManager.auth.getRoute(),
              state: { from: props?.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
