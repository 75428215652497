import { useRef, useState } from 'react';

export const useAccountMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>();
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  return { handleClose, handleOpen, isOpen, menuId: 'account-menu', anchorRef };
};
