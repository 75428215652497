import { gql } from '@apollo/client';

import FULL_USER_FRAGMENT from 'graphql/fragments/companyUser/fullCompanyUserFrag';

export default gql`
  ${FULL_USER_FRAGMENT}
  query CurrentCompanyUser {
    currentCompanyUser {
      ...FullUser
    }
  }
`;
