import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
// import { ReactComponent as GroupsIcon } from 'assets/icons/groups.svg';
import { ReactComponent as InviteIcon } from 'assets/icons/invite.svg';
import { ReactComponent as ProjectsIcon } from 'assets/icons/projects.svg';
import { ReactComponent as UserProfileIcon } from 'assets/icons/user_profile.svg';
import { DrawerListItemProps } from 'components/layout/drawer/drawer-list-item';
import { pathManager } from 'routes/consts';

export const DEFAULT_TALENT_ITEMS: DrawerListItemProps[] = [
  {
    link: pathManager.talent.jobBoard.generatePath(),
    text: 'Job Board',
    Icon: ProjectsIcon,
  },
  {
    link: pathManager.talent.profile.generatePath(),
    text: 'Profile',
    Icon: UserProfileIcon,
  },
  // { link: LINKS.talent.groups, text: 'Groups', Icon: GroupsIcon },
  {
    link: pathManager.talent.invite.generatePath(),
    text: 'Invite & Earn',
    Icon: InviteIcon,
  },
];
export const DEFAULT_COMPANY_ITEMS: DrawerListItemProps[] = [
  {
    link: pathManager.company.dashboard.generatePath(),
    text: 'Dashboard',
    Icon: DashboardIcon,
    testName: 'company-dashboard-drawer-link',
  },
  {
    link: pathManager.company.jobBoard.generatePath(),
    text: 'My Jobs',
    Icon: ProjectsIcon,
    testName: 'company-job-board-drawer-link',
  },
  { link: pathManager.company.workforce.generatePath(), text: 'Talent' },
  {
    link: pathManager.company.createProfile.generatePath(),
    text: 'Invite',
    Icon: InviteIcon,
  },
];
export const NOT_ACCEPTED_TALENT_ITEMS = [
  {
    link: pathManager.talent.editProfile.generatePath(),
    text: 'Profile',
    Icon: UserProfileIcon,
  },
];
