import React from 'react';

import PageLayout from 'components/layout/page-layout';

import { ProfileScreenProps } from './types';

const Profile = React.lazy(() => import('./Profile'));

const LazyProfile = (props: ProfileScreenProps) => {
  return (
    <React.Suspense
      fallback={
        <PageLayout
          isLoading
          headerProps={{ isLoading: true }}
          drawerProps={{ isLoading: true }}
        />
      }
    >
      <Profile {...props} />
    </React.Suspense>
  );
};

export default LazyProfile;
