import { PopupButton } from '@typeform/embed-react';
import React from 'react';

import { Fab, makeStyles } from '@material-ui/core';

import * as auth0Utils from 'auth0/utils';
import { useAuth0 } from 'hooks/auth/useAuth0';

interface TypeFormButtonProps {}

const COMPANY_FORM_ID = 'PZQC7nXF';
const TALENT_FORM_ID = 'gj9nG4nh';

const useStyles = makeStyles({
  btn: {
    position: 'fixed',
    right: 40,
    bottom: 40,
    width: 60,
    height: 60,
    zIndex: 10,
    background:
      'url(https://images.typeform.com/images/AZesmPtAjfbC) no-repeat center',
    backgroundSize: 'contain',
  },
});

const TypeFormButton = (props: TypeFormButtonProps) => {
  const classes = useStyles();
  const { user: auth0User } = useAuth0();
  const isTalent = auth0Utils.isTalent(auth0User);
  const formId = isTalent ? TALENT_FORM_ID : COMPANY_FORM_ID;

  return (
    <PopupButton as={Fab as any} className={classes.btn} id={formId}>
      {' '}
    </PopupButton>
  );
};

export default TypeFormButton;
