import { gql } from '@apollo/client';

export default gql`
  fragment FullUser on User {
    id
    email
    first_name
    last_name
    position
    company {
      id
      name
      logo
      website
      type_of_activity
      contract {
        id
        status
      }
      has_contract
    }
    created_at
    updated_at
    avatar {
      avatar
      hash
    }
  }
`;
