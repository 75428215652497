import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import PageLayout from 'components/layout/page-layout';

const Job = React.lazy(() => import('./Job'));

const LazyJob = (props: RouteComponentProps<{ id: string }>) => {
  return (
    <React.Suspense
      fallback={
        <PageLayout
          isLoading
          headerProps={{ isLoading: true }}
          drawerProps={{ isLoading: true }}
        />
      }
    >
      <Job {...props} />
    </React.Suspense>
  );
};

export default LazyJob;
