import { useApolloClient, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import {
  Query,
  Notification,
  Mutation,
  MutationDeleteNotificationArgs,
  MutationUpdateNotificationArgs,
} from 'generated/graphql-types';
import {
  GET_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_AS_READ,
} from 'graphql/notifications';

import { DELETE_NOTIFICATION, UPDATE_NOTIFICATION } from './queries';

export const useNotifications = () => {
  const apolloClient = useApolloClient();
  const data = apolloClient.readQuery<Query>({
    query: GET_NOTIFICATIONS,
  });

  return data;
};

export const useChangeNotificationStatus = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [changeStatus, { loading }] = useMutation<
    Mutation,
    MutationUpdateNotificationArgs
  >(UPDATE_NOTIFICATION);

  const handleChangeStatus = ({
    status,
    notification,
    notify,
  }: {
    status: boolean;
    notification: Notification;
    notify?: boolean;
  }) => {
    const nextIsRead = status !== undefined ? status : !notification.is_read;
    changeStatus({
      variables: {
        id: notification.id,
        is_read: nextIsRead,
      },
      update: (cache) => {
        cache.modify({
          fields: {
            currentUserUnreadNotificationsCount(val) {
              return val > 0 ? val + (nextIsRead ? -1 : 1) : 0;
            },
          },
        });
      },
    });
    if (notify) {
      enqueueSnackbar(
        `Notification marked as ${notification?.is_read ? 'not read' : 'read'}`,
        { variant: 'success' },
      );
    }
  };

  return { handleChangeStatus, isStatusLoading: loading };
};

export const useRemoveNotification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const [deleteNotification, { loading }] = useMutation<
    Mutation,
    MutationDeleteNotificationArgs
  >(DELETE_NOTIFICATION, {
    onCompleted: () => {
      enqueueSnackbar(`Notification was deleted`, {
        variant: 'success',
      });
    },
  });

  const handleDelete = (notification: Notification) => {
    deleteNotification({
      variables: {
        notification_id: notification.id,
      },
    });
    client.cache.evict({
      id: client.cache.identify(notification),
    });
  };

  return { handleDelete, isDeleteInProgress: loading };
};

export const useMarkAllAsRead = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [mutate, { loading }] = useMutation<Mutation>(
    MARK_ALL_NOTIFICATIONS_AS_READ,
    {
      refetchQueries: [{ query: GET_NOTIFICATIONS }],
      onCompleted: () => {
        enqueueSnackbar(`All notifications marked as read`, {
          variant: 'success',
        });
      },
    },
  );

  const markAllAsRead = () => {
    mutate();
  };

  return { markAllAsRead, isMarkingInProgress: loading };
};
