import React from 'react';
import { RouteProps } from 'react-router-dom';

import PageLayout from 'components/layout/page-layout';

const Workforce = React.lazy(() => import('./Workforce'));

const LazyWorkforce = (props: RouteProps) => {
  return (
    <React.Suspense
      fallback={
        <PageLayout
          isLoading
          headerProps={{ isLoading: true }}
          drawerProps={{ isLoading: true }}
        />
      }
    >
      <Workforce {...props} />
    </React.Suspense>
  );
};

export default LazyWorkforce;
