import React from 'react';

import { Box } from '@material-ui/core';
import AppBar, { AppBarProps } from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';

import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { ReactComponent as MobileLogoIcon } from 'assets/icons/mobile-logo.svg';
import Account, { AccountProps } from 'components/layout/account';
import useMediaQueries from 'hooks/common/useMediaQueries';

import Notifications from './notifications';
import useStyles from './styles';
import TalentInvites from './talent-invites';

export interface HeaderProps extends AppBarProps {
  accountProps?: AccountProps | null;
  toggleDrawer?: VoidFunction;
  isLoading?: boolean;
  inventionCount?: number;
}

const Header = (props: HeaderProps) => {
  const {
    accountProps,
    toggleDrawer,
    isLoading = false,
    inventionCount,
    ...rest
  } = props;
  const classes = useStyles(props);
  const { isSM } = useMediaQueries();
  const LogoComponent = isSM ? MobileLogoIcon : LogoIcon;

  return (
    <AppBar className={classes.header} {...rest} color="secondary">
      <Toolbar>
        <Grid alignItems="center" justify="space-between" container>
          <Grid spacing={2} xs={3} alignItems="center" container item>
            {toggleDrawer && (
              <Hidden mdUp>
                <Grid item>
                  <IconButton
                    onClick={() => toggleDrawer()}
                    size="small"
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
            )}
            <Grid item>
              <LogoComponent className={classes.logo} />
            </Grid>
          </Grid>
          <Grid item>
            <Grid alignItems="center" container>
              {(accountProps || (accountProps && isLoading)) && (
                <>
                  <Grid component={Box} pr={2} item>
                    <Notifications isLoading={isLoading} />
                  </Grid>
                  <Grid item>
                    <TalentInvites
                      inventionCount={inventionCount}
                      isLoading={isLoading}
                    />
                  </Grid>
                  <Grid item>
                    <Account isLoading={isLoading} {...(accountProps || {})} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
