import {
  NotificationActionRedirect,
  NotificationActionTypeEnum,
  RedirectTargeRouteTypeEnum,
  NotificationActionInterface,
} from 'generated/graphql-types';
import { pathManager } from 'routes';

const getRedirectLink = (action: NotificationActionRedirect) => {
  switch (action.target_route_type) {
    case RedirectTargeRouteTypeEnum.TalentJobInvitation:
      return pathManager.talent.jobInvitation.generatePath({
        id: action.target_route_id,
      });
    case RedirectTargeRouteTypeEnum.CompanyUserJob:
      return pathManager.company.job.generatePath({
        id: action.target_route_id,
      });
    case RedirectTargeRouteTypeEnum.TalentJob:
      return pathManager.talent.jobApply.generatePath({
        id: action.target_route_id,
      });
    default:
      // eslint-disable-next-line no-console
      console.error('Unknown redirect type', action);
      return '';
  }
};

export const getActionLink = (
  action: NotificationActionInterface | NotificationActionRedirect,
) => {
  switch (action.type) {
    case NotificationActionTypeEnum.Redirect:
      return getRedirectLink(action as NotificationActionRedirect);
    case NotificationActionTypeEnum.Confirm:
      return getRedirectLink(action as NotificationActionRedirect);
    default:
      // eslint-disable-next-line no-console
      console.error('Unknown action type', action);
      return '';
  }
};
