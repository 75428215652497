import React from 'react';

import PageLayout from 'components/layout/page-layout';

import { ScreenProps } from './types';

const JobInvitation = React.lazy(() => import('./JobInvitation'));

const InviteByTalentLazy = (props: ScreenProps) => (
  <React.Suspense
    fallback={<PageLayout isLoading headerProps={{ isLoading: true }} />}
  >
    <JobInvitation {...props} />
  </React.Suspense>
);

export default InviteByTalentLazy;
