import { gql } from '@apollo/client';

import FULL_TALENT_FRAGMENT from 'graphql/fragments/talent/fullTalentFragment';

export default gql`
  ${FULL_TALENT_FRAGMENT}
  query GetCurrentTalent {
    currentTalent {
      ...FullTalent
    }
  }
`;
