import { JobMarkType } from 'types';
import { RoutePath } from 'utils/routes';

export const pathManager = {
  auth: new RoutePath<never>('/'),
  auth0: new RoutePath<never>('/auth0'),
  logout: new RoutePath<never>('/logout'),
  quickAuth: new RoutePath<never>('/quick-auth'),
  abnamro: new RoutePath<never>('/abnamro'),
  corpLanding: new RoutePath<never>('/companies'),
  invitation: new RoutePath<never>('/invitation'),
  notifications: new RoutePath<never>('/notifications'),
  company: {
    workforce: new RoutePath<never>('/company'),
    dashboard: new RoutePath<never>('/dashboard'),
    createProfile: new RoutePath<never>('/company/create-profile'),
    talentProfile: new RoutePath<{ id: string }>('/company/talent-profile/:id'),
    settings: {
      main: new RoutePath<never>('/company/settings'),
    },
    users: {
      edit: new RoutePath<{ id: string }>('/company/user/:id'),
      create: new RoutePath<never>('/company/user/create'),
    },
    jobBoard: new RoutePath<never>('/company/job-board'),
    onboarding: {
      main: new RoutePath<never>('/company/onboarding'),
      greeting: new RoutePath<never>('/company/onboarding/greeting'),
      form: new RoutePath<never>('/company/onboarding/form'),
      intro: new RoutePath<never>('/company/onboarding/intro'),
    },

    job: new RoutePath<{ id: string }>('/company/job/:id'),
    editJob: new RoutePath<{ id: string }>('/company/edit-job/:id'),
    duplicateJob: new RoutePath<{ id: string }>('/company/duplicate-job/:id'),
    newJob: new RoutePath<never>('/company/new-job'),
    chooseNewJobType: new RoutePath<never>('/company/choose-new-job-type'),
  },
  talent: {
    profile: new RoutePath<never>('/talent'),
    groups: new RoutePath<never>('/talent/groups'),
    editProfile: new RoutePath<never>('/talent/edit-profile'),
    jobBoard: new RoutePath<never>('/talent/job-board'),
    invite: new RoutePath<never>('/invites'),
    invitation: new RoutePath<{ key: string }>('/invitation/:key'),
    invitationSuccess: new RoutePath<never>('/invitation/success'),
    invitationInUse: new RoutePath<never>('/invitation/in-use'),
    jobApply: new RoutePath<{ id: string }>('/talent/job-apply/:id'),
    markedJobs: new RoutePath<{ type: JobMarkType }>(
      '/talent/marked-jobs/:type',
    ),
    jobInvitation: new RoutePath<{ id: string }>('/talent/job-invitation/:id'),
    onboarding: {
      main: new RoutePath<never>('/talent/onboarding'),
      form: new RoutePath<never>('/talent/onboarding/form'),
      intro: new RoutePath<never>('/talent/onboarding/intro'),
    },
  },
};

export const ANONYMOUS_LOCATIONS = [pathManager.corpLanding.generatePath()];
