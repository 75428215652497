import { SimplePaletteColorOptions } from '@material-ui/core/styles';
import { TypeText } from '@material-ui/core/styles/createPalette';

import { PALETTE } from './palette';
import { TYPOGRAPHY_THEME_OPTIONS } from './typography';

export const INPUT_THEME_OPTIONS = {
  MuiTextField: {
    root: {
      // overflow: 'hidden',
    },
  },
  MuiInput: {
    underline: {
      '&:before': {
        borderBottomColor: PALETTE.other.dark ?? 'rgba(25, 25, 25, 0.24)',
      },
      '&:after': {
        borderBottomColor: PALETTE.other.black ?? '#282824',
      },
      '&$disabled': {
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
    },
  },
  MuiFormControl: {
    root: {
      minWidth: 198,
    },
    marginDense: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  MuiInputBase: {
    root: {
      background: 'white',
    },
    inputMarginDense: {
      ...TYPOGRAPHY_THEME_OPTIONS.body2,
    },
  },
  MuiOutlinedInput: {
    root: {
      '&$focused $notchedOutline': {
        borderColor: PALETTE.other.black ?? '#282824',
      },
    },
    notchedOutline: {
      borderColor: PALETTE.other.main ?? 'rgba(25, 25, 25, 0.08)',
    },
    inputAdornedStart: {
      paddingLeft: 8,
    },
  },
  MuiFilledInput: {
    root: {
      border: `1px solid ${PALETTE.other.main ?? 'rgba(25, 25, 25, 0.08)'}`,
      borderBottom: 'none',
      backgroundColor: 'none',
      '&$focused': {
        backgroundColor: 'none',
      },
      '&:hover': {
        backgroundColor: 'none',
      },
      '&$disabled': {
        backgroundColor: 'none',
      },
    },
    underline: {
      '&:before': {
        borderBottomColor: PALETTE.other.dark ?? 'rgba(25, 25, 25, 0.24)',
      },
      '&:after': {
        borderBottomColor: PALETTE.other.black ?? '#282824',
      },
      '&$disabled': {
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
    },
    // inputMarginDense: {
    //   paddingTop: 16,
    // },
  },
  MuiFormHelperText: {
    contained: {
      marginLeft: 0,
    },
  },
  MuiInputLabel: {
    root: {
      ...TYPOGRAPHY_THEME_OPTIONS.body1,
      '&$focused': {
        color: (PALETTE.text as TypeText).primary ?? 'rgba(0, 0, 0, 0.54)',
        '&$error': {
          color: (PALETTE.error as SimplePaletteColorOptions).main ?? '#EC634E',
        },
      },
    },
    marginDense: {
      transform: 'translate(0, 19px) scale(1)',
      ...TYPOGRAPHY_THEME_OPTIONS.body2,
    },
    formControl: {
      transform: 'translate(0, 19px) scale(1)',
    },
    outlined: {
      '&:after': {},
      transform: 'translate(12px, 16px) scale(1)',
      '&$marginDense': {
        transform: 'translate(12px, 8px) scale(1)',
      },
      '&$focused': {
        color: PALETTE.other.black ?? '#282824',
      },
      '&$shrink': {
        transform: 'translate(16px, -8px) scale(0.75)',
      },
    },
    filled: {
      transform: 'translate(12px, 16px) scale(1)',
      '&$marginDense': {
        transform: 'translate(12px, 12px) scale(1)',
      },
      '&$focused': {
        color: PALETTE.other.black ?? '#282824',
      },
    },
  },
};
