import { makeStyles, createStyles } from '@material-ui/core/styles';

import { StylesAccountProps } from './Account';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    textMultiline: {
      margin: 0,
    },
    positionText: {
      maxWidth: 160,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    itemListAvatar: ({ isSM }: StylesAccountProps) => ({
      ...(isSM ? { minWidth: 'auto' } : {}),
    }),
    skeleton: {
      opacity: 0.1,
      margin: `0 ${theme.spacing(3)}px`,
    },
  }),
);

export default useStyles;
