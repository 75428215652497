import React from 'react';

import { Box } from '@material-ui/core';
import MuiDrawer, {
  DrawerProps as MuiDrawerProps,
} from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { ReactComponent as SlackIcon } from 'assets/icons/slack.svg';
import { Skeleton } from '@material-ui/lab';

import Button from 'components/button';
import useMediaQueries from 'hooks/common/useMediaQueries';

import DrawerList from './drawer-list';
import { DrawerListItemProps } from './drawer-list-item';
import useStyles from './styles';

export interface DrawerProps extends MuiDrawerProps {
  items?: DrawerListItemProps[];
  isLoading?: boolean;
}

const Drawer = (props: DrawerProps) => {
  const { items, isLoading, ...rest } = props;
  const classes = useStyles(props);
  const { isSM } = useMediaQueries();

  return (
    <MuiDrawer
      {...rest}
      className={classes.drawer}
      variant={isSM ? 'temporary' : 'permanent'}
      classes={{
        paper: classes.paper,
      }}
    >
      <Toolbar />
      {!isLoading && <DrawerList items={items} />}
      {isLoading &&
        Array(4)
          .fill('')
          .map((_, key) => (
            <Skeleton
              key={key as any}
              height="60px"
              className={classes.skeleton}
            />
          ))}
      <Box
        display="flex"
        flexGrow={1}
        alignItems="flex-end"
        pb={3}
      >
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
            <a href="https://wa.me/31623240550" target="_blank" rel="noreferrer">
              <Button className={classes.contactBtn} startIcon={<WhatsAppIcon />}>
                contact us
              </Button>
            </a>
            <a href="https://join.slack.com/t/opentalentcommunity/shared_invite/zt-wgcocyxg-63qpP96wR5biBAF5GGmlOw" target="_blank" rel="noreferrer">
              <Button className={classes.contactBtn} startIcon={<SlackIcon />}>
                we&apos;re on Slack
              </Button>
            </a>
        </Box>
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
