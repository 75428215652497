import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import { ThemeProvider } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';

import { usePageTitle } from 'hooks/common/usePageTitle';
import partialNewTheme from 'themes/partial-new';

import Drawer, { DrawerProps } from '../drawer';
import Header, { HeaderProps } from '../header';
import DefaultContentSkeleton from './DefaultContentSkeleton';
import useStyles from './styles';

export interface PageLayoutProps {
  children?: React.ReactNode | React.ReactNode[];
  drawerProps?: DrawerProps;
  headerProps?: HeaderProps;
  documentTitle?: string;
  contentSpacing?: number;
  centered?: boolean;
  isLoading?: boolean;
  ContentSkeleton?: React.ElementType;
  classes?: {
    contentWrapper: string;
  };
}

const PageLayout = (props: PageLayoutProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const {
    drawerProps,
    headerProps,
    children,
    documentTitle,
    isLoading,
    ContentSkeleton = DefaultContentSkeleton,
    classes: definedClasses,
  } = props;
  const classes = useStyles(props);
  const toggleDrawer = useCallback(() => setIsDrawerOpen((s) => !s), []);

  usePageTitle(documentTitle);

  return (
    <div className={classes.root}>
      <ThemeProvider theme={partialNewTheme}>
        <Header
          toggleDrawer={drawerProps ? toggleDrawer : undefined}
          position="fixed"
          {...headerProps}
        />
        {drawerProps && (
          <Drawer {...drawerProps} open={isDrawerOpen} onClose={toggleDrawer} />
        )}
      </ThemeProvider>
      <main className={classes.content}>
        <Toolbar />
        <div
          className={cn(classes.contentWrapper, {
            [definedClasses?.contentWrapper as string]: definedClasses?.contentWrapper,
          })}
        >
          {isLoading ? <ContentSkeleton /> : children}
        </div>
      </main>
    </div>
  );
};

export default PageLayout;
