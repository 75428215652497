import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Theme } from 'themes/default';

import { DRAWER_WIDTH } from '../consts';
import { HeaderProps } from './Header';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      zIndex: theme.zIndex.drawer + 1,

      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: -1,
        left: ({ toggleDrawer }: HeaderProps) =>
          toggleDrawer ? DRAWER_WIDTH : 0,
        height: 1,
        width: ({ toggleDrawer }: HeaderProps) =>
          toggleDrawer ? `calc(100% - ${DRAWER_WIDTH}px)` : '100%',
        background: theme.palette.grey[300],

        [theme.breakpoints.down('sm')]: {
          'body &': {
            left: '0',
            width: '100%',
          },
        },
      },
    },
    logo: {
      marginTop: theme.spacing(1),
    },
    badge: {
      top: 3,
      right: 3,
    },
    skeleton: {
      // backgroundColor: 'white',
      opacity: 0.1,
      margin: `0 ${theme.spacing(3)}px`,
    },
  }),
);

export default useStyles;
