import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Theme } from 'themes/default';

import { DrawerListItemProps } from './DrawerListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      ...theme.typography.subtitle2,
      fontWeight: theme.typography.fontWeightMedium,
      color: 'inherit',
    },
    icon: {
      color: 'inherit',
    },
    caret: {
      color: theme.palette.secondary.contrastText,
    },
    badge: {
      right: theme.spacing(3),
      paddingTop: 2,
    },
    listItem: {
      color: ({ isActive }: DrawerListItemProps) =>
        isActive ? theme.palette.text.primary : theme.palette.text.secondary,
      background: ({ isActive }: DrawerListItemProps) =>
        isActive ? 'rgba(30, 30, 28, 0.08)' : undefined,

      '&::before': {
        content: ({ isActive }: DrawerListItemProps) => (isActive ? "''" : ''),
        width: 3,
        height: theme.spacing(8),
        background: theme.palette.tertiary.main,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        position: 'absolute',
        left: 0,
      },
    },
  }),
);

export default useStyles;
